import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@/libs/axios'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import shipmentModel from '@/views/models/orders/shipmentModel'

const {
  fetchAll,
  fetchOne,
  create,
  // update,
  // deleteMany,
} = useModelStoreModule(shipmentModel)

const updateStatus = (_, { id, status }) => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.BACKEND_HOST}/${jwtDefaultConfig.DASHBOARD_ENDPOINT}/${shipmentModel.name}/${id}`, { status })
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(shipmentModel.fromArray(response.data.data))
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

export default {
  name: 'orders',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    create,
    // update,
    updateStatus,
    // deleteMany,
  },
}
