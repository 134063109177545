<template>
  <b-modal
    id="modal-add-shipment"
    :visible.sync="isVisible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Create')"
    :cancel-title="$t('Cancel')"
    centered
    :title="$t('modules.shipping_methods.actions.create_shipment')"
    @hidden="$emit('hidden')"
    @ok.prevent="onFormSubmit"
  >
    <validation-observer
      ref="refFormObserver"
    >
      <b-form @submit.stop.prevent="onFormSubmit">
        <template v-if="orderData.shipping_address">
          <b-form-group>
            <label for="courier">{{ $t('modules.shipping_methods.inputs.shipping_to_customer') }}</label>
            <b-form-input
              id="customer_name"
              type="text"
              readonly="readonly"
              :value="orderData.shipping_address.name"
            />
          </b-form-group>
          <b-form-group>
            <label for="courier">{{ $t('modules.shipping_methods.inputs.shipping_to_address') }}</label>
            <b-form-input
              id="customer_address"
              type="text"
              readonly="readonly"
              :value="countryCityDetails"
            />
          </b-form-group>
        </template>
        <b-form-group>
          <label for="courier">{{ $t('inputs.shipping_method') }}</label>
          <b-form-input
            id="courier"
            type="text"
            placeholder="Courier"
            readonly="readonly"
            :value="orderData.shipping_method.name"
          />
        </b-form-group>
        <b-form-group>
          <i-input
            v-model="shipmentData.tracking_id"
            :errors="validationErrors.tracking_id"
            type="text"
            title="modules.shipping_methods.inputs.tracking_number"
            description="modules.shipping_methods.inputs.tracking_number:description"
            placeholder="xxxxxxxxxxxxx"
          />
        </b-form-group>
      <!--      <b-form-group>-->
      <!--        <i-input-->
      <!--          v-model="orderData.shipping_method.status"-->
      <!--          :errors="validationErrors.status"-->
      <!--          type="select"-->
      <!--          :options="shipmentStatusOptions"-->
      <!--          title="modules.shipping_methods.inputs.shipment_status"-->
      <!--          description="modules.shipping_methods.inputs.shipment_status:description"-->
      <!--        />-->
      <!--      </b-form-group>-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'

import {
  computed,
  onUnmounted, ref,
} from 'vue'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import shipmentStoreModule from '@/views/models/orders/shipmentStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    BFormInput,
    BModal,
    BFormGroup,
    // BSV
    BForm,

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    orderData: {
      type: Object,
      default: null,
    },
    shipmentStatusOptions: {
      type: Array,
      default: Array,
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props, ctx) {
    const { t } = useI18nUtils()
    // ? This is just dummy details
    const shipmentData = ref({
      id: null,
      order_id: props.orderData.id,
      uuid: null,
      items: [],
      tracking_id: null,
    })

    const isLoading = ref(false)
    const refs = ref({})

    const SHIPMENTS_STORE_MODULE_NAME = 'shipments'

    // Register module
    if (!store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.registerModule(SHIPMENTS_STORE_MODULE_NAME, shipmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SHIPMENTS_STORE_MODULE_NAME)) store.unregisterModule(SHIPMENTS_STORE_MODULE_NAME)
    })

    const {
      validationErrors,
      save,
    } = useModelUpdate(
      SHIPMENTS_STORE_MODULE_NAME,
      refs,
      shipmentData,
      data => {
        ctx.emit('hidden')
        ctx.emit('submitted')
      },
      null,
      null,
    )

    const onFormSubmit = () => {
      save()
    }

    const countryCityDetails = computed(() => {
      /** @REF: src/views/models/orders/add/OrderInfoCard.vue */
      let value = `${props?.orderData?.shipping_address?.line_1}, `
      if (props.orderData.shipping_address.city) {
        value += `${props.orderData.shipping_address?.city.name}, `
      } else if (props.orderData.shipping_address.city_name) {
        value += `${props.orderData.shipping_address?.city_name}, `
      }
      if (props.orderData.shipping_address.state) {
        value += `${props.orderData.shipping_address?.state.name}, `
      }
      if (props.orderData.shipping_address.country) {
        value += `${props.orderData.shipping_address?.country.name}`
      }
      return value
    })

    return {
      isLoading,
      shipmentData,
      refs,
      validationErrors,
      countryCityDetails,
      onFormSubmit,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

</style>
