<template>
  <b-card :title="$t('modules.activity_logs.title')">
    <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="pt-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- comment box -->
          <b-form-group>
            <i-input
              v-model="activityLogData.comment"
              title="modules.activity_logs.actions.note"
              no-label
              rows="2"
              maxlength="250"
              type="textarea"
              :placeholder="'📝' + $t('modules.activity_logs.inputs.note:placeholder')"
              :validation-rules="['min:3']"
            />
          </b-form-group>
          <!--/ comment box -->

          <Transition name="fade-bottom">
            <b-button
              v-if="activityLogData.comment"
              size="sm"
              variant="primary"
              type="submit"
            >
              {{ $t('modules.activity_logs.actions.add_note') }}
            </b-button>
          </Transition>

        </b-form>
      </validation-observer>
      <hr>
      <app-timeline>

        <!-- Activity -->
        <app-timeline-item
          v-for="activity in activityLogs"
          :key="activity.id"
          :variant="activity.color"
          :icon="activity.icon"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>
              {{ $t('modules.activity_logs.action_options.' + String(activity.action).toLowerCase()) }}
              <span v-if="activity.action === 'COMMENT'">
                : "{{ activity.properties.comment }}"
              </span>
              <span v-else-if="activity.action === 'UPDATE_STATUS'">
                : "{{ (activity.properties.status in $t('modules.orders.status')) ? $t('modules.orders.status.' + activity.properties.status) : activity.properties.status }}"
              </span>
              <span v-else-if="activity.action === 'UPDATE_PAYMENT_STATUS'">
                : "{{ activity.properties.is_paid ? $t('modules.payments.status.paid') : $t('modules.payments.status.not_paid') }}"
              </span>
            </h6>
            <span class="timeline-item-time text-nowrap text-muted">
              <b-avatar
                v-b-tooltip.hover="activity.actor ? activity.actor.name + ' (' + $t('modules.activity_logs.actor_type_options.' + activity.actor.type) + ')' : ''"
                class="mr-1"
                :text="activity.actor ? avatarText(activity.actor.name) : '?'"
                :variant="activity.actor ? (activity.actor.type === 'Merchant' ? 'light-primary' : 'light-info') : 'light-secondary'"
                :size="30"
              />
              <small
                v-text="formatDatetime(activity.created_at)"
              />
            </span>
          </div>
          <!--          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">-->
          <!--            <small class="text-muted">{{ formatDatetime(activity.created_at) }}</small>-->
          <!--          </div>-->
          <!--          <p v-if="activity.action === 'COMMENT'">-->
          <!--            {{ activity.properties.comment }}-->
          <!--          </p>-->
          <!--          <p v-else>-->
          <!--            {{ activity.properties }}-->
          <!--          </p>-->

          <b-media v-if="0 && activity.actor">
            <template #aside>
              <b-avatar
                :text="avatarText(activity.actor.name)"
                :variant="activity.actor.type === 'Merchant' ? 'light-primary' : 'light-info'"
              />
            </template>
            <h6>{{ activity.actor.name }}</h6>
            <p class="mb-0">
              {{ $t('modules.activity_logs.actor_type_options.' + activity.actor.type) }}
            </p>
          </b-media>
          <p
            v-if="activity.file"
            class="mb-0"
          >
            <b-img
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="20"
              class="mr-1"
            />
            <span class="align-bottom">{{ activity.file }}</span>
          </p>
        </app-timeline-item>
      </app-timeline>

      <p
        v-if="!activityLogs || !activityLogs.length"
        class="text-center"
      >
        {{ $t('modules.activity_logs._messages.no_activities_to_show') }}
      </p>
      <a
        v-else-if="activityLogListPagination.current_page < activityLogListPagination.last_page"
        class="text-center d-block"
        @click="loadMore"
      >
        <feather-icon icon="ChevronDownIcon" />
        {{ $t('modules.activity_logs._messages.load_more') }}
      </a>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BButton, BFormGroup, BOverlay, BForm, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { avatarText, formatDatetime } from '@core/utils/filter'
import { onMounted, onUnmounted, ref } from 'vue'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import store from '@/store'
import activityLogStoreModule from '@/views/models/activity_logs/activityLogStoreModule'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    BForm,
    BOverlay,
    BFormGroup,
    BButton,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    subjectType: {
      type: String,
      required: true,
    },
    subjectId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: { formatDatetime, avatarText },
  setup(props) {
    const STORE_MODULE_NAME = 'activity_logs'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, activityLogStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, activityLogStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const activityLogListData = ref([])
    const activityLogListPagination = ref({})
    const activityLogData = ref({
      subject_type: props.subjectType,
      subject_id: props.subjectId,
      comment: null,
    })
    const refs = ref({})
    const { save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      activityLogData,
      () => {
      },
      null,
      null,
      () => {
      },
    )

    const isLoading = ref(false)
    const fetchActivityLogs = page => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`, {
        subject_type: props.subjectType,
        subject_id: props.subjectId,
        page,
      })
        .then(response => {
          activityLogListPagination.value = response.data.meta
          if (response.data.meta.current_page === 1) {
            activityLogListData.value = response.data.data
          } else {
            activityLogListData.value = activityLogListData.value.concat(response.data.data)
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchActivityLogs(1)

    const resetForm = () => {
      activityLogData.value.comment = null
    }
    const submitForm = () => {
      isLoading.value = true
      save(false, () => {
        resetForm()
        fetchActivityLogs(1)
      }, null, () => {
        isLoading.value = false
      })
    }

    const loadMore = () => {
      if (activityLogListPagination.value.current_page >= activityLogListPagination.value.last_page) {
        return
      }

      fetchActivityLogs(activityLogListPagination.value.current_page + 1)
    }

    return {
      refs,
      isLoading,
      activityLogData,
      activityLogs: activityLogListData,
      activityLogListPagination,
      loadMore,
      submitForm,
      resetForm,
      // activityLogs: [
      //   {
      //     id: 9,
      //     actor: {
      //       type: 'Merchant',
      //       id: 5,
      //       name: 'Super Admin',
      //     },
      //     action: 'COMMENT',
      //     properties: {
      //       comment: 'Order needs attention',
      //     },
      //     date: '2024-04-24',
      //     color: 'warning',
      //   },
      // ],
    }
  },
}
</script>

<style>

</style>
