<template>
  <b-card
    no-body
    :bg-variant="isActive ? 'default' : 'transparent'"
  >
    <b-card-header>
      <!--      <b-card-title>-->
      <!--        <label-->
      <!--          v-t="`inputs.shipping_method`"-->
      <!--          class="section-label"-->
      <!--        />-->
      <!--      </b-card-title>-->

      <i-input
        :errors="errors"
        type="select"
        title="inputs.shipping_method"
        class="w-100"
        :validation-rules="['required']"
        :options="options"
        :clearable="true"
        :disabled="readonly"
        :value="selected.name"
        label="name"
        @input=" val => $emit('update:selected', val || {})"
      />
    </b-card-header>
    <b-card-body
      v-if="false"
      class="with-border"
    >

      <label
        v-t="`Shipments`"
        class="section-label my-1"
      />
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>Biscuit jelly beans macaroon danish pudding.</span>
          <b-badge
            variant="primary"
            pill
            class="badge-round"
          >
            In transit
          </b-badge>
        </b-list-group-item>

        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>chocolate cheesecake candy</span>
          <b-badge
            variant="primary"
            pill
            class="badge-round"
          >
            Delivered
          </b-badge>
        </b-list-group-item>

        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span class="text-secondary">The order has no any shipments.</span>
        </b-list-group-item>
        <b-list-group-item

          v-if="isAddingShipmentsAllowed"

          class="d-flex justify-content-between align-items-center"
        >
          <b-button
            variant="primary"
            pill
            class="badge-round btn-block"
          >
            Add New
          </b-button>

        </b-list-group-item>

        <b-alert
          v-if="!isAddingShipmentsAllowed"
          variant="info"
          class="mt-1"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25">You can add shipment after order creation.</span>

          </div>
        </b-alert>
      </b-list-group>
    </b-card-body>

    <b-card-footer
      v-if="shipments.length"
    >
      <div
        v-t="`modules.orders._shipments.shipments`"
        class="detail-title detail-total"
      />
      <div
        v-for="(shipment, key) in shipments"
        :key="key"
        class="text-muted"
      >
        <div>
          <feather-icon
            icon="HashIcon"
            class="mr-75"
          />
          <span>{{ shipment.tracking_id }}</span>
        </div>
        <div>
          <feather-icon
            icon="CheckIcon"
            class="mr-75"
          />
          <span>{{ $t(`modules.orders._shipments.status`) }}: {{ shipment.status_description }}</span>
        </div>
        <div>
          <feather-icon
            icon="CalendarIcon"
            class="mr-75"
          />
          <span>{{ $t(`modules.orders._shipments.created_at`) }}: {{ formatDatetime(shipment.created_at) }}</span>
        </div>
        <div>
          <feather-icon
            icon="CalendarIcon"
            class="mr-75"
          />
          <span>{{ $t(`modules.orders._shipments.last_update_date`) }}: {{ shipment.last_update_date ? formatDatetime(shipment.last_update_date) : '-' }}</span>
        </div>
      </div>

    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BBadge,
  BListGroup,
  BListGroupItem,
  BAlert,
  BCardHeader, BCardBody, BDropdownItem, BCardFooter, BDropdown,
} from 'bootstrap-vue'
import { formatDatetime } from '../../../../@core/utils/filter'

export default {
  components: {
    BDropdown,
    BCardFooter,
    BDropdownItem,
    // BSV
    BCard,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
    BAlert,

    BCardHeader,
    BCardBody,
  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    isAddingShipmentsAllowed: {
      type: Boolean,
      default: false,
    },
    shipments: {
      type: Array,
      default: Array,
      required: false,
    },
    errors: {
      type: Array,
      default: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: { formatDatetime },
  setup(_, { emit }) {
  },
}
</script>

<style lang="scss">
[dir] .dark-layout .card .card-body.with-border {
  border-color: #3b4253;
}
[dir] .card .card-body.with-border {
  border-top: 1px solid #dae1e7;
  border-top-color: rgb(218, 225, 231);
}
</style>
