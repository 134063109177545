<template>
  <b-card
    class="list-view order-items-list"
    bg-variant="transparent"
    style="border: 1px dashed;border-radius: 10px;"
  >

    <b-card-title>
      <label
        v-t="`modules.orders._create.order_content`"
        class="section-label"
      />
    </b-card-title>
    <div class="checkout-items">
      <!-- New Item Card -->
      <template
        v-if="!readonly"
      >
        <b-card
          key="new-item"
          ref="newItemCard"
          bg-variant="transparent"
          border-variant="primary"
          class="ecommerce-card empty-card shadow-none"
          no-body
        >

          <!-- Product Image -->
          <div class="item-img">
            <b-img
              :src="item.image ? storagePath(item.image) : ImagePlaceholder"
              :alt="item.name ? item.name : 'Product Image'"
            />
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <v-select
                v-model="item.name"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :clearable="true"
                class="item-selector-title"
                :placeholder="$t(`modules.orders._create._messages.select_product`)"
                :options="options"
                :value="selected.name"
                @input="val => updateItemInfo(val)"
              />
              <span class="item-company"><b-link class="company-name">{{ item.category }}</b-link></span>
            </div>

            <div class="mb-1">

              <span
                v-if="item.has_unlimited_qty || item.qty>0"
                v-t="`In Stock`"
                class="text-success"
              />
              <span
                v-else
                v-t="`Out Of Stock`"
                class="text-danger"
              />
              <span v-if="!item.has_unlimited_qty && item.qty>0"> - {{ $tc(`x Items Available`, item.qty) }}</span>

            </div>

            <!--        <span class="text-success mb-1">In Stock</span>-->
            <div class="qty-price-row mb-1">
              <div class="row-item">
                <div class="item-quantity">
                  <span class="quantity-title">{{ $t('Qty') }}:</span>
                  <b-form-spinbutton
                    v-model="item.selected_qty"
                    size="sm"
                    class="ml-75 w-100"
                    style="min-width: 90px"
                    :min="1"
                    :max="item.has_unlimited_qty ? null : item.qty"
                    inline
                    :disabled="!item.product_id"
                  />
                </div>
              </div>
              <div class="row-item">
                <div class="item-quantity">
                  <span class="quantity-title">{{ $t('Price') }}:</span>
                  <cleave
                    id="number"
                    v-model="item.price"
                    class="form-control form-control-sm mx-1"
                    :raw="false"
                    :options="{numeral: true, numeralThousandsGroupStyle: 'thousand', numeralDecimalScale: 2}"
                    placeholder="100.00"
                    :disabled="!item.product_id"
                  />
                </div>
              </div>
            </div>
            <span
              v-if="item.min_per_order && item.selected_qty < item.min_per_order"
              v-t="`modules.orders._create._messages.min_per_order_limit`"
              class="text-warning"
            />
            <span
              v-if="item.limit_per_order && item.selected_qty > item.limit_per_order"
              v-t="`modules.orders._create._messages.max_per_order_limit`"
              class="text-warning"
            />
          </b-card-body>

          <!-- Product Options/Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  {{ $n((parseFloat(String(item.price).replace(',','')) || 0) * (item.selected_qty || 0), 'currency', 'en-US') }}

                  <!--              <b-badge-->
                  <!--                v-if="!item.price"-->
                  <!--                pill-->
                  <!--                variant="light-success"-->
                  <!--                style="width: 50px"-->
                  <!--              >&nbsp;</b-badge>-->
                </h4>
                <p
                  v-if="item.free_shipping"
                  class="card-text shipping"
                >
                  <b-badge
                    v-t="`Free Shipping`"
                    pill
                    variant="light-success"
                  />
                </p>
              </div>
            </div>
            <!--        <b-button-->
            <!--          variant="outline-secondary"-->
            <!--          class="mt-1 remove-wishlist"-->
            <!--          @click="resetItemData"-->
            <!--        >-->
            <!--          <feather-icon-->
            <!--            icon="XIcon"-->
            <!--            class="mr-50"-->
            <!--          />-->
            <!--          <span>Reset</span>-->
            <!--        </b-button>-->
            <b-button
              :variant="!item.product_id || !item.selected_qty || !item.price ? `outline-primary` : `primary`"
              class="btn-cart move-cart"
              :disabled="!item.product_id || !item.selected_qty || !item.price"
              @click="addItem"
            >
              <template v-if="item.product_id && isProductExistsInCart(item.product_id)">

                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span
                  v-t="`Save Changes`"
                  class="text-nowrap"
                />
              </template>
              <template v-else>

                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span
                  v-t="`Add Item`"
                  class="text-nowrap"
                />
              </template>
            </b-button>
          </div>
        </b-card>

        <hr>
      </template>
      <!-- Existent Items Cards -->
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="item-img">
          <b-img
            height="120px"
            width="auto"
            :src="product.image ? storagePath(product.image) : ImagePlaceholder"
            :alt="`${product.name}-${product.id}`"
          />
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link
                class="text-body"
                :to="product.product_group_id ? {name: 'products.edit', params: {id: product.product_group_id}} : null"
                target="_blank"
              >
                {{ product.name }}
              </b-link>
            </h6>
            <span class="item-company"><b-link class="company-name">{{ product.category }}</b-link></span>
          </div>
          <ul
            v-if="product.options_values"
            class="mb-1"
          >
            <li
              v-for="(optionValue, k) in product.options_values"
              :key="`option-value-${k}`"
              class="text-muted"
            >
              {{ optionValue[`option_${$i18n.locale}`] }}: {{ optionValue[`value_${$i18n.locale}`] }}
            </li>
          </ul>
          <div class="mb-1">

            <span
              v-if="product.has_unlimited_qty || product.qty>0"
              v-t="`In Stock`"
              class="text-success"
            />
            <span
              v-else
              v-t="`Out Of Stock`"
              class="text-danger"
            />
            <span v-if="!product.has_unlimited_qty && product.qty>0"> - {{ $tc(`x Items Available`, product.qty) }}</span>

          </div>
          <div class="item-quantity">
            <span class="quantity-title">{{ $t('Qty') }}:</span>
            <b-form-spinbutton
              v-model="product.selected_qty"
              size="sm"
              class="ml-75"
              :min="1"
              :max="product.has_unlimited_qty ? null : product.qty"
              inline
              :disabled="readonly"
              @input="triggerItemsUpdatedEvent"
            />
            <span class="mx-1">x</span>
            <span>{{ $n(parseFloat(String(product.price).replace(',','')), 'currency', 'en-US') }}</span>
          </div>
          <span
            v-if="product.min_per_order && product.selected_qty < product.min_per_order"
            v-t="`modules.orders._create._messages.min_per_order_limit`"
            class="text-warning"
          />
          <span
            v-if="product.limit_per_order && product.selected_qty > product.limit_per_order"
            v-t="`modules.orders._create._messages.max_per_order_limit`"
            class="text-warning"
          />

          <!--          <span class="delivery-date text-muted">{{ $t(`modules.orders._create.unit_price`) }}: {{ $n(parseFloat(String(product.price).replace(',','')), 'currency', 'en-US') }}</span>-->
        <!--        <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} offers Available</span>-->

        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ $n((parseFloat(String(product.price).replace(',','')) || 0) * (product.selected_qty || 0), 'currency', 'en-US') }}
              </h4>
              <p
                v-if="product.free_shipping"
                class="card-text shipping"
              >
                <b-badge
                  v-t="`Free Shipping`"
                  pill
                  variant="light-success"
                />
              </p>
            </div>
          </div>
          <b-button
            v-if="!readonly"
            variant="light"
            class="mt-1 edit-wishlist"
            @click="onClickItemEdit(product)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              v-t="`modules.orders._create.edit_price`"
              class="text-nowrap"
            />
          </b-button>
          <b-button
            v-if="!readonly"
            variant="danger"
            class="btn-cart move-cart"
            @click="removeItem(product.product_id)"
          >
            <!--            :disabled="readonly"-->
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span
              v-t="`Remove`"
              class="text-nowrap"
            />
          </b-button>
        </div>
      </b-card>

      <b-alert
        v-if="!products.length"
        variant="warning"
        class="mt-1"
        show
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="AlertTriangleIcon"
          />
          <span
            v-t="`modules.orders._create._messages.no_items`"
            class="ml-25"
          />
          <span
            v-if="!readonly"
            v-t="`modules.orders._create._messages.add_items_from_box_above`"
            class="ml-25"
          />

        </div>
      </b-alert>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
  VBToggle,
  BAlert,
} from 'bootstrap-vue'
import { ref, watch } from 'vue'
import { formatDate } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { BCardTitle } from 'bootstrap-vue/src/components/card'
import { storagePath } from '@core/utils/utils'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    vSelect,

    BCardTitle,
    Cleave,
    BAlert,
  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    products: {
      type: Array,
      default: Array,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    products(newValue) {
      this.currentProducts = newValue
    },
  },
  methods: { storagePath },
  emits: ['update:selected', 'update:products'],
  setup(props, ctx) {
    // products.value = [
    //   // {
    //   //   id: 1,
    //   //   name: 'VicTsing Wireless Mouse,',
    //   //   category: 'VicTsing',
    //   //   price: 10.99,
    //   //   image: require('@/assets/images/pages/eCommerce/27.png'),
    //   //   free_shipping: true,
    //   //   selected_qty: 3,
    //   //   qty: 10,
    //   //   has_unlimited_qty: false,
    //   //   limit_per_order: 5,
    //   //   min_per_order: 2,
    //   //   shippingDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    //   //   offers: 2,
    //   //   discountPercentage: 5,
    //   // },
    // ]

    const emptyItemData = {
      id: null,
      product_id: null,
      name: null,
      category: null,
      price: null,
      qty: null,
      has_unlimited_qty: true,
      limit_per_order: null,
      min_per_order: null,
      image: null,
      free_shipping: false,
      selected_qty: null,
      shippingDate: new Date(),
      offers: 0,
      discountPercentage: 0,
      items: [],
    }
    const item = ref({})
    const newItemCard = ref()
    const resetItemData = () => {
      item.value = JSON.parse(JSON.stringify(emptyItemData))
      item.value.selected_qty = null
    }

    resetItemData()

    const ImagePlaceholder = require('@/assets/images/pages/eCommerce/placeholder.webp')

    const products = ref([...props.products])
    const triggerItemsUpdatedEvent = () => {
      ctx.emit('update:products', products.value)
    }
    watch(products, () => {
      triggerItemsUpdatedEvent()
    }, { deep: true })

    const updateItemInfo = value => {
      if (!value) {
        resetItemData()
        return
      }
      // item.value = JSON.parse(JSON.stringify(val))
      const val = JSON.parse(JSON.stringify(value))
      item.value.product_id = val.id || val.product_id
      item.value.name = val.name
      item.value.category = val.category
      item.value.price = val.price.amount / 100
      item.value.qty = val.qty
      item.value.has_unlimited_qty = val.has_unlimited_qty
      item.value.limit_per_order = val.limit_per_order
      item.value.min_per_order = val.min_per_order
      item.value.image = val.image
      item.value.free_shipping = val.free_shipping

      const productIndexInCart = getProductIndexInCart(item.value.product_id)
      item.value.selected_qty = val.selected_qty || val.min_per_order || 1
      if (productIndexInCart >= 0) {
        item.value.selected_qty = products.value[productIndexInCart].selected_qty
        item.value.price = products.value[productIndexInCart].price
      }
      if (!val.has_unlimited_qty && item.value.selected_qty > val.qty) {
        item.value.selected_qty = 0
      }
    }

    const onClickItemEdit = product => {
      if (newItemCard.value) {
        newItemCard.value.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
      updateItemInfo({ ...product })
    }

    const getProductIndexInCart = itemId => {
      // Search in attribute 'id' of items
      const resultOfSearchById = products.value.findIndex(p => p.id === itemId)
      if (resultOfSearchById > -1) {
        return resultOfSearchById
      }
      // Search in attribute 'product_id' of items
      return products.value.findIndex(p => p.product_id === itemId)
    }
    const isProductExistsInCart = productId => getProductIndexInCart(productId) >= 0
    const addItem = () => {
      if (item.value.product_id) {
        const productIndex = getProductIndexInCart(item.value.product_id)
        if (productIndex >= 0) {
          let newQty = item.value.selected_qty /* + products.value[productIndex].selected_qty */
          if (!item.value.has_unlimited_qty && newQty > item.value.qty) {
            newQty = Math.min(newQty, item.value.qty)
          }
          products.value[productIndex].selected_qty = newQty
          products.value[productIndex].price = item.value.price
        } else {
          products.value.unshift({
            ...JSON.parse(JSON.stringify(item.value)),
          })
        }
        resetItemData()
      }
    }

    const removeItem = itemId => {
      const productIndex = getProductIndexInCart(itemId)
      if (productIndex >= 0) {
        products.value.splice(productIndex, 1)
      }
    }

    return {
      // products,
      currentProducts: products,
      item,
      onClickItemEdit,
      updateItemInfo,
      addItem,
      removeItem,
      resetItemData,
      triggerItemsUpdatedEvent,
      isProductExistsInCart,

      // Refs
      newItemCard,

      // UI
      ImagePlaceholder,

      // Filter
      formatDate,
    }
  },
}
</script>

<style>
[dir] .order-items-list .v-select ul {
  position: absolute;
  top: auto;
  left: auto;
  width: inherit;
}

[dir] .ecommerce-application .ecommerce-card.empty-card:hover {
  transform: unset;
  box-shadow: unset;
}
.ecommerce-application .list-view .ecommerce-card {
  overflow: unset !important;
}

[dir] .ecommerce-application .checkout-items .ecommerce-card .item-img img {
  height: 120px;
  width: auto;
}

.checkout-items .card-body .qty-price-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.checkout-items .card-body .qty-price-row .row-item {
  margin: 0.5rem 1rem;
  flex: 1 1 12rem;
  min-width: 12rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
</style>
