<template>
  <b-card
    no-body
  >
    <b-card-header>
      <!--      <b-card-title>-->
      <!--        <label-->
      <!--            v-t="`modules.customers.s_c`"-->
      <!--            class="section-label"-->
      <!--        />-->
      <!--      </b-card-title>-->
      <i-input
        :errors="errors"
        type="select"
        title="modules.customers.s_c"
        class="w-100"
        :validation-rules="['required']"
        :options="options"
        :clearable="true"
        :value="selected.name"
        label="name"
        @input=" val => $emit('update:selected', val || {})"
      />
      <!--      @input="v => $emit('update:model-value', v)"-->
      <!--                :reduce="val => val.value"-->
    </b-card-header>
<!--    <b-card-body class="card-profile with-border pt-2">-->

<!--      <div class="d-flex justify-content-center align-items-center mt-1">-->
<!--        <div class="mr-1">-->
<!--          <b-avatar-->
<!--            size="64"-->
<!--            :text="avatarText(selected.name)"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="profile-user-info">-->
<!--          <h6 class="mb-0">-->
<!--            {{ selected.name }}-->
<!--          </h6>-->
<!--          <div>-->
<!--            <small class="text-muted">{{ selected.email }}</small>-->
<!--          </div>-->
<!--          <div>-->
<!--            <small class="text-muted ltr"><span class="d-block">{{ selected.formatted_mobile }}</span></small>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="!selected.id"-->
<!--          class="profile-user-info"-->
<!--        >-->
<!--          <h6 class="mb-0">-->
<!--            <b-badge-->
<!--              pill-->
<!--              variant="light-secondary"-->
<!--              style="width: 120px; height: 21px"-->
<!--            >&nbsp;</b-badge>-->
<!--          </h6>-->
<!--          <small class="text-muted text-left">-->
<!--            <b-badge-->
<!--              pill-->
<!--              variant="light-secondary"-->
<!--              class="mt-50 mr-1"-->
<!--              style="width: 100px; height: 16px;"-->
<!--            >&nbsp;</b-badge>-->
<!--          </small>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-card-body>-->
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BListGroup,
  BCardText,
  BListGroupItem,
  BAlert,
  BCardGroup,
  BCardTitle,
  BCardFooter, BCardBody, BCardHeader, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    // BSV
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
    BAlert,

    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BCardBody,
    BBadge,
    BAvatar,
  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    errors: {
      type: Array,
      default: Array,
      required: false,
    },
  },
  methods: { avatarText },
  emits: ['update:selected'],
  setup(_, { emit }) {
    return {
    }
  },
}
</script>

<style lang="scss">
[dir] .dark-layout .card .card-body.with-border {
  border-color: #3b4253;
}
[dir] .card .card-body.with-border {
  border-top: 1px solid #dae1e7;
  border-top-color: rgb(218, 225, 231);
}
</style>
