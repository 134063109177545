<template>
  <b-card
    no-body
    :bg-variant="isActive ? 'default' : 'transparent'"
  >

    <b-card-header>
      <!--      <b-card-title>-->
      <!--        <label-->
      <!--          v-t="`modules.addresses.s_c`"-->
      <!--          class="section-label"-->
      <!--        />-->
      <!--      </b-card-title>-->
      <i-input
        :errors="errors"
        type="select"
        title="modules.addresses.s_c"
        class="w-100"
        :validation-rules="['required']"
        :options="options"
        :clearable="true"
        :value="selected.description"
        label="description"
        @input=" val => $emit('update:selected', val ? {...val, address_id: val.id} : {})"
      />

      <!--                  :reduce="val => val.id"-->
      <!--        TODO: Future feature -->
      <!--        <template #list-header>-->
      <!--          <li-->
      <!--            v-b-toggle.sidebar-add-new-address-->
      <!--            class="add-new-client-header d-flex align-items-center my-50"-->
      <!--          >-->
      <!--            <feather-icon-->
      <!--              icon="PlusIcon"-->
      <!--              size="16"-->
      <!--            />-->
      <!--            <span-->
      <!--              v-t="`modules.addresses.actions.add_new`"-->
      <!--              class="align-middle ml-25"-->
      <!--            />-->
      <!--          </li>-->
      <!--        </template>-->
    </b-card-header>
<!--    <b-card-body class="with-border">-->

<!--      <dl-->
<!--        v-if="selected.address_id"-->
<!--        class="row pt-1"-->
<!--      >-->
<!--        <dt-->
<!--          v-t="`modules.addresses.details.name`"-->
<!--          class="col-sm-3 text-right"-->
<!--        />-->
<!--        <dd class="col-sm-9">-->
<!--          {{ selected.first_name || '-' }} {{ selected.last_name || '' }}-->
<!--        </dd>-->
<!--        <dt-->
<!--          v-t="`modules.addresses.details.mobile`"-->
<!--          class="col-sm-3 text-right"-->
<!--        />-->
<!--        <dd class="col-sm-9">-->
<!--          <span class="ltr"><span class="d-inline-block">{{ selected.formatted_mobile || '-' }}</span></span>-->
<!--        </dd>-->
<!--        <dt-->
<!--          v-t="`modules.addresses.s_c`"-->
<!--          class="col-sm-3 text-right"-->
<!--        />-->
<!--        <dd class="col-sm-9">-->
<!--          {{ selected.line_1 }}-->
<!--          <br>-->
<!--          {{ selected.line_2 }}-->
<!--        </dd>-->
<!--        <dt-->
<!--          v-t="`modules.addresses.details.country_city`"-->
<!--          class="col-sm-3 text-right"-->
<!--        />-->
<!--        <dd class="col-sm-9">-->
<!--          {{ selected.city.name }}, {{ selected.country.name }}-->
<!--        </dd>-->
<!--        <dt-->
<!--          v-t="`modules.addresses.details.short_address`"-->
<!--          class="col-sm-3 text-right"-->
<!--        />-->
<!--        <dd class="col-sm-9">-->
<!--          {{ selected.short_address }}-->
<!--        </dd>-->
<!--      </dl>-->
<!--      <b-card-->
<!--        v-else-->
<!--      >-->
<!--        <b-card-text class="text-center">-->
<!--          <div>-->
<!--            <b-badge-->
<!--              pill-->
<!--              variant="light-secondary"-->
<!--              style="width: 40%; height: 21px"-->
<!--            >&nbsp;</b-badge>-->
<!--          </div>-->
<!--          <div>-->
<!--            <b-badge-->
<!--              pill-->
<!--              variant="light-secondary"-->
<!--              class="mt-1"-->
<!--              style="width: 30%; height: 21px"-->
<!--            >&nbsp;</b-badge>-->
<!--          </div>-->
<!--        </b-card-text>-->
<!--      </b-card>-->

<!--    </b-card-body>-->

<!--    <sidebar-add-new-address-->
<!--      :is-sidebar-active.sync="isSidebarActive"-->
<!--      @refetch-data="$emit('submit')"-->
<!--    />-->
    <!--    @refetch-data="loadAddressesOfCustomer(orderData.customer_id)"-->
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BListGroup,
  BCardText,
  BListGroupItem,
  BAlert,
  BCardGroup,
  BCardTitle,
  BCardFooter, BCardBody, BCardHeader, BAvatar, VBToggle,
} from 'bootstrap-vue'
import { ref } from 'vue'
import SidebarAddNewAddress from '@/views/models/orders/add/SidebarAddNewAddress.vue'

export default {
  components: {
    SidebarAddNewAddress,
    // BSV
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
    BAlert,

    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BCardBody,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-toggle': VBToggle,

  },
  props: {
    options: {
      type: Array,
      default: Array,
    },
    selected: {
      type: Object,
      default: Object,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: Array,
      required: false,
    },
  },
  setup(props, { emit }) {
    const isSidebarActive = ref(false)

    return {
      isSidebarActive,
    }
  },
}
</script>

<style lang="scss">
//@import '@core/scss/vue/libs/vue-select.scss';
//.invoice-add-wrapper {
//  .add-new-client-header {
//    padding: $options-padding-y $options-padding-x;
//    color: $success;
//
//    &:hover {
//      background-color: rgba($success, 0.12);
//    }
//  }
//}

[dir] .dark-layout .card .card-body.with-border {
  border-color: #3b4253;
}
[dir] .card .card-body.with-border {
  border-top: 1px solid #dae1e7;
  border-top-color: rgb(218, 225, 231);
}
</style>
